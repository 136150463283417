import { paletteText, globalVariables } from '@styles/01-settings'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { fontFamilies, fontWeights } from '@styles/01-settings/settings.font'
import { oFontFamily } from '@styles/05-objects/objects.typography'

export default `
  html {
    background-color: ${paletteText.secondary_background};
    ${oFontFamily(fontFamilies.baseFamily)};
    font-weight: ${fontWeights.regular};
    font-size: ${globalVariables.smallerFontSize}px;
    line-height: 1.28;
    color: ${paletteText.text};
    min-height: 100%;
    overflow-y: scroll;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    &:before {
      content: "LUKARNA STUDIO";
      font-size: 1.5rem;
      font-weight: ${fontWeights.light};
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    /* 16px from 1280px */
    ${breakpoint.gt('lg')`
      font-size: ${globalVariables.smallerFontSize}px;
    `}

    &.has-blocked-scroll {
      overflow-y: hidden;
      overflow: hidden;
    }
  }
`
