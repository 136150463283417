module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lukarna","accessToken":"MC5ZTHZXV1JJQUFDSUE2TWhs.d--_ve-_vRx7Xe-_vSvvv71r77-9He-_ve-_vRJw77-9Uixx77-977-977-977-977-977-9L--_ve-_vUQ377-9","toolbar":"new","promptForAccessToken":true,"apiEndpoint":"https://lukarna.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"is_gt_xs":"(min-width: 600px)","is_lt_xs":"(max-width: 600px)","is_gt_sm":"(min-width: 768px)","is_lt_sm":"(max-width: 768px)","is_gt_md":"(min-width: 992px)","is_lt_md":"(max-width: 992px)","is_gt_lg":"(min-width: 1280px)","is_lt_lg":"(max-width: 1280px)","is_gt_xl":"(min-width: 1600px)","is_lt_xl":"(max-width: 1600px)","is_gt_hd":"(min-width: 1920px)","is_lt_hd":"(max-width: 1920px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
