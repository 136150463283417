import { ObjProps } from '@models/common.model'

export const layers: ObjProps<number> = {
  layer_under_zero: -1,
  layer_zero: 0,
  layer_1: 45,
  layer_2: 46,
  layer_3: 47,
  layer_4: 48,
  layer_5: 49,
  layer_99: 99,
}
