import { globalVariables } from '@styles/01-settings'
import { rem } from '@styles/02-tools/tools.common'

export default `
  p, p > span {
    font-size: ${rem(globalVariables.smallFontSize)}rem;
  }

  sup {font-size:small; vertical-align:super;}

`
