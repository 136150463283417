import React, { ReactNode } from 'react'

import { GlobalStyles } from '@styles/index'
import { GlobalHelmet } from '@components/global-helmet'
import { GlobalContextProvider } from '@context/global-context-provider'

type IndexProps = {
  children: ReactNode
}

const IndexLayout = ({ children }: IndexProps) => {
  return (
    <GlobalContextProvider>
      <GlobalHelmet />
      <GlobalStyles />
      {children}
    </GlobalContextProvider>
  )
}

export { IndexLayout }
