export default `
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 89, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(255, 89, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 89, 89, 0);
    }
  }
`
