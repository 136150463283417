import { breakpoints } from '@styles/01-settings'
import { bp } from '@styles/02-tools/tools.breakpoints'

export default `
  .u-hidden {
    display: none;
  }

  // When use .u-hidden-small then element will be display only from 768px
  .u-hidden-small {
    display: none;

    ${bp(breakpoints.sm, `display: block;`)}
  }

  // When use .u-hidden-medium then element will be display only from 992px
  .u-hidden-medium {
    display: none;

    ${bp(breakpoints.md, `display: block;`)}
  }

  // When use .u-hidden-large then element will be display only from 1200px
  .u-hidden-large {
    display: none;

    ${bp(breakpoints.lg, `display: block;`)}
  }
`
