import { globalVariables } from '@styles/01-settings'
import { rem } from '@styles/02-tools/tools.common'

export default `
  a, .default-link {
    font-size: ${rem(globalVariables.smallFontSize)}rem;
    text-decoration: none;
    width: fit-content;
    display: inline-block;
    cursor: pointer;
    position: relative;
  }
`
