/**
 * SEO / Global Helmet component
 */

import React, { useState, useContext } from 'react'
import { Helmet } from 'react-helmet'

import { GenerateLinksPreconnect } from './links-preconnect'
import { componentDidMount } from '@utils/lifecycles'
import {
  GlobalDispatchContext,
  SCRIPT_LOADED_SUCCESS,
} from '@context/global-context-provider'

export const GlobalHelmet = () => {
  const [loaded, setLoaded] = useState(false)
  const dispatch = useContext(GlobalDispatchContext) as any
  const isSSR = typeof window === 'undefined'

  componentDidMount(() => {
    setLoaded(true)
  })

  function onConversationsAPIReady(): void {
    // wait until script execution finish and widget will be visible
    setTimeout(() => {
      if (dispatch) {
        dispatch({ type: SCRIPT_LOADED_SUCCESS })
      }
    }, 1500)
  }

  if (!isSSR) {
    window.hsConversationsOnReady = [onConversationsAPIReady]
  }

  return (
    <Helmet>
      <meta content="on" httpEquiv="x-dns-prefetch-control" />
      <meta content="none" httpEquiv="x-rim-auto-match" />
      {GenerateLinksPreconnect()}

      <style>{`
        :root {
          background-color: #1B1A1A;
        }
      `}</style>
    </Helmet>
  )
}
