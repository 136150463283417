import { colors, borderRadius, fontWeights } from '@styles/01-settings'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { transition } from '@styles/02-tools/tools.common'

export default `
  form {
    width: 100%;
    min-width: 100%;
  }

  input, textarea {
    font-family: inherit;
    width: 100%;
    appearance: none;
    border: none;
    padding: 1rem;
    border: 2px solid ${colors.gray.darkest};
    border-radius: ${borderRadius.default};
    margin-bottom: 4px;
    min-height: 42px;

    &:not([type="submit"]):hover, &:not([type="submit"]):focus {
      outline: none;
    }

    &[type="submit"] {
      font-weight: ${fontWeights.bold};
      color: ${colors.base.white};
      background: ${colors.gray.darkest};
      transition: ${transition()};
      cursor: pointer;
      width: 100%;
      font-size: 1.375rem;

      &:hover, &:focus {
        color: ${colors.gray.darkest};
        background: ${colors.base.white};
        outline: none;
      }

      ${breakpoint.gt('md')`font-size: 1rem;`}
    }
  }

  textarea {
    resize: vertical;
  }
`
