import styled from '@emotion/styled'

import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { font } from '@styles/01-settings/settings.font'
import { globalVariables } from '@styles/01-settings'

// #region internal tool
export const oFontFamily = (fontFamily: string) =>
`font-family: '${fontFamily}', 'Arial', 'Helvetica', sans-serif;`

export const oFontSize = (sizeInRem: number) => `font-size: ${sizeInRem}rem;`

export const oLineHeight = (lineHeightInPx: number) =>
  `line-height: ${lineHeightInPx}rem;`
//#endregion internal tool

// #region Hero superscription
export const oHeroSuperscription = () => `
  font-size: ${globalVariables.smallestFontSize}px;
  font-weight: ${font.weights.semiBold};
  color: ${font.colors.dark};
  letter-spacing: ${font.spacings.small}px;
  text-transform: uppercase;
`

export const HeroSuperscription = styled('h1')`
  ${oHeroSuperscription()}
  ${oLineHeight(font.heights.smallest)}
`
// #endregion Hero superscription

// #region Hero title
export const oHeroTitle = () => `
  ${oFontSize(2.32)}
  ${oLineHeight(2.76)}
  font-weight: ${font.weights.bold};
  color: ${font.colors.default};

  ${breakpoint.gt('md')`
    ${oFontSize(font.sizes.largest)}
    ${oLineHeight(font.heights.large)}
  `}
`

export const HeroTitle = styled('h2')`
  ${oHeroTitle()}
`
// #endregion Hero title

//#region Hero lead
export const oHeroLead = () => `
  ${oFontSize(font.sizes.default)}
  ${oLineHeight(font.heights.small)}
  font-weight: ${font.weights.regular};
  color: ${font.colors.default};

  a {
    text-decoration: underline;
  }
`

export const HeroLead = styled('h3')`
  ${oHeroLead()}
`
//#endregion Hero lead

export const oSectionTitle = () => `
  ${oFontSize(font.sizes.large)}
  font-weight: ${font.weights.bold};
  color: ${font.colors.dark};
  line-height: 2.8rem;

  ${breakpoint.gt('md')`
    ${oLineHeight(font.heights.regular)}
  `}
`

export const oSuperscription = () => `
  ${oFontSize(font.sizes.small)}
  font-weight: ${font.weights.semiBold};
  color: ${font.colors.dark};
  letter-spacing: ${font.spacings.small}px;
  text-transform: uppercase;

  ${breakpoint.gt('md')`
    ${oFontSize(font.sizes.smallest)}
  `}
`

export const oFeatureTitle = () => `
  ${oFontSize(font.sizes.large)}
  ${oLineHeight(font.heights.regular)}
  font-weight: ${font.weights.bold};
  color: ${font.colors.dark};
`

export const oFeatureDescription = () => `
  ${oFontSize(font.sizes.default)}
  ${oLineHeight(font.heights.small)}
  font-weight: ${font.weights.regular};
  color: ${font.colors.default};
`

export const oReferral = () => `
  ${oFontSize(font.sizes.default)}
  ${oLineHeight(font.heights.small)}
  font-style: italic;
  color: ${font.colors.dark};

  ${breakpoint.gt('md')`
    ${oFontSize(font.sizes.small)}
    ${oLineHeight(font.heights.smaller)}
  `}
`

export const oReferralAuthor = () => `
  ${oFontSize(font.sizes.default)}
  ${oLineHeight(font.heights.small)}
  color: ${font.colors.default};

  ${breakpoint.gt('md')`
    ${oFontSize(font.sizes.small)}
    ${oLineHeight(font.heights.smaller)}
  `}
`

export const oReferralTitleLarge = () => `
  ${oFontSize(font.sizes.huge)}
  ${oLineHeight(font.heights.largest)}
  font-weight: ${font.weights.bold};
  color: ${font.colors.special};
`

export const oFeatureListTitle = () => `
  ${oFontSize(font.sizes.small)}
  ${oLineHeight(font.heights.smaller)}
  font-weight: ${font.weights.semiBold};
  color: ${font.colors.dark};
  text-transform: none;

  ${breakpoint.gt('md')`
    ${oFontSize(font.sizes.smaller)}
    ${oLineHeight(font.heights.smallest)}
  `}
`
export const oFeatureListItem = () => `
  ${oFontSize(font.sizes.small)}
  ${oLineHeight(font.heights.smaller)}
  color: ${font.colors.dark};

  ${breakpoint.gt('md')`
    ${oFontSize(font.sizes.smaller)}
    ${oLineHeight(font.heights.smallest)}
  `}
`

export const oSectionTitleLarge = () => `
  ${oFontSize(font.sizes.larger)}
  ${oLineHeight(font.heights.larger)}
  font-weight: ${font.weights.bold};
  color: ${font.colors.dark};
`
