import { fontWeights, paletteText } from '@styles/01-settings'

export default `
  .company.name {
    font-size: 1.5rem;
    font-weight: ${fontWeights.light};
    display: block;
  }

  .light.text {
    color: #6B6A6A;
  }

  .link.with.arrow {
    display: block;
    padding: 13px 12px;
    border: 1px solid ${paletteText.text};
    color: ${paletteText.text};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 3px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: ${paletteText.text};
      border: 1px solid ${paletteText.text};
      color: ${paletteText.secondary_text};
      border-radius: 30px;
    }
  }

  .link.with.arrow.white {
      border: 1px solid ${paletteText.secondary_text};
      color: ${paletteText.secondary_text};
   }
`
