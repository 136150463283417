export enum globalVariables {
  hugeFontSize = 80,
  largestFontSize = 60,
  largerFontSize = 56,
  largeFontSize = 36,
  defaultFontSize = 22,
  smallFontSize = 18,
  smallerFontSize = 16,
  smallestFontSize = 14,
}
