import generic from './03-generic/'
import elements from './04-elements/'
import components from './06-components/'
import utilities from './09-utilities/'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  ${generic}
  ${elements}
  ${components}
  ${utilities}
`
