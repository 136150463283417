export const rem = (size: number) => size / 16 // TODO this should not be hardcoded
export const transition = (propertyToTransition: string = 'all') =>
  `${propertyToTransition} 250ms ease-out`
export const noSelect = () => `
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
