import { ObjProps } from '@models/common.model'

export const borderColors: ObjProps<string> = {
  lighter: '#cfcfd6',
  default: '#e9e9f1',
  dark: '#dbdae5',
}

export const borderRadius: ObjProps<string> = {
  largest: '14px',
  larger: '8px',
  large: '6px',
  default: '4px',
  small: '3px',
  round: '50%',
}
