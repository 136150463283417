import { ColorProps, PalettesProps } from '@models/colors.model'
import { ObjProps } from '@models/common.model'

export const colors: ColorProps = {
  base: {
    white: '#fff',
    black: '#1B1A1A',
  },
  brand: {
    lightest: '#f5f4fd',
    lighter: '#e4e1fd',
    light: '#b2a9f8',
    default: '#444fd',
    dark: '#4e0cdb',
    darker: '#4d0dce',
    darkest: '#052998',
  },
  blue: {
    lightest: '#f3f8ff',
    lighter: '#b9d7ff',
    light: '#769eff',
    default: '#1a69ef',
    dark: '#0b4ec0',
    darker: '#003080',
    darkest: '#01134d',
  },
  gray: {
    lightest: '#f2f2f7',
    lighter: '#cfcfd6',
    light: '#797789',
    default: '#4d4b61',
    dark: '#383556',
    darker: '#292641',
    darkest: '#151320',
  },
  red: {
    lightest: '#ffeeee',
    lighter: '#ffc4c4',
    light: '#ff9393',
    default: '#ff5959',
    dark: '#eb3131',
    darker: '#cd2222',
    darkest: '#9c1313',
  },
  other: {
    lightest: '#fcfbfe',
    lighter: '#cfcfd6',
    light: '#797789',
    default: '#080E10',
    dark: '#383556',
    darker: '#292641',
    darkest: '#151320',
  },
  milk: {
    lightest: '#fcfbfe',
    lighter: '#f7f6f9',
    light: '#e9e9f1',
    default: '#dbdae5',
    dark: '#cdcbde',
    darker: '#c0bed3',
    darkest: '#a09fb3',
  },
}

export const paletteText: PalettesProps = {
  headings: colors.other.default,
  text: colors.base.black,
  secondary_text: colors.base.white,
  text_light: colors.gray.light,
  background: colors.base.white,
  secondary_background: colors.base.black,
  border: colors.gray.lightest,
  link: {
    current: colors.blue.dark,
    default: colors.blue.default,
    hover: colors.blue.dark,
  },
  highlighted: '#533B00',
}

export const paletteBackground: ObjProps<string> = {
  highlighted: '#F8DB92',
}
