export default `
  .u-spaces-0 {
    margin: 0 !important;
    padding: 0 !important;
  }

  .u-spaces-y0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .u-spaces-x0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .u-spaces-t0 {
    margin-top: 0 !important;
    padding-top: 0 !important;

    &:not(:last-of-type) {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    }
  }

  .u-spaces-b0 {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
`
