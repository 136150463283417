const Bowser = require('bowser')

const breakpoints = {
  xs: 600,
  sm: 768,
  md: 992,
  lg: 1280,
  xl: 1600,
  hd: 1920,
}

/**
 * @description Breakpoint template helpers
 *
 * import { useBreakpoint } from 'gatsby-plugin-breakpoints'
 *
 * const breakpoint = useBreakpoint()
 *
 * breakpoint.is_gt_xs
 * breakpoint.is_lt_xl
 */

const breakpointQueries = Object.entries(breakpoints).reduce(
  (accumulator, current) => {
    accumulator['is_gt_' + current[0]] = `(min-width: ${current[1]}px)`
    accumulator['is_lt_' + current[0]] = `(max-width: ${current[1]}px)`
    return accumulator
  },
  {},
)

function crmBrowserSupport() {
  if (typeof window === 'undefined') {
    return null
  }
  const crmUnsupportedBrowsers = {
    'Internet Explorer': 11,
    Chrome: 49,
    Firefox: 56,
  }

  const browserAnalyzer = Bowser.getParser(window.navigator.userAgent)

  const browserVersion = browserAnalyzer.getBrowserVersion()

  const browserInfo = {
    name: browserAnalyzer.getBrowserName(),
    version: browserVersion ? parseInt(browserVersion.split('.')[0], 10) : null,
    mobile: browserAnalyzer.getPlatformType(true) === 'mobile',
  }

  const supported =
    browserInfo.version && crmUnsupportedBrowsers[browserInfo.name]
      ? browserInfo.version > crmUnsupportedBrowsers[browserInfo.name]
      : true

  return {
    ...browserInfo,
    supported,
  }
}

module.exports = {
  breakpointQueries,
  breakpoints,
  crmBrowserSupport,
}
