import { breakpoint } from '@styles/02-tools/tools.breakpoints'

export default `
  .c-footer {
      padding-top: 4.125rem;
      padding-bottom: 1.375rem;
      text-align: center;

      ${breakpoint.gt('md')`
        padding-top: 8.125rem;
        padding-bottom: 1.375rem;
      `}
  }
`
