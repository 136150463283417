import React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

import { IndexLayout } from './src/layouts/index'

export const wrapPageElement = ({ element, props }) => (
  <PrismicPreviewProvider>
    <IndexLayout {...props}>{element}</IndexLayout>
  </PrismicPreviewProvider>
)

/**
 * Do feature detection,
 * to figure out which polyfills needs to be imported.
 **/
export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

// Declare Console.logDev
console.logDev = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...args)
  }
}
