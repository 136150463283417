import { useEffect, useRef } from 'react'

export const componentDidMount = (handler: () => void) => {
  return useEffect(() => {
    return handler()
  }, [])
}

export const componentDidUpdate = (handler: () => void, deps: any[] = []) => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false

      return
    }

    return handler()
  }, deps)
}
