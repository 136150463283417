import {
  fontSizes,
  fontColor,
  fontWeights,
  lineHeights,
} from '@styles/01-settings'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { oFontSize, oLineHeight } from '@styles/05-objects/objects.typography'

export default `
h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.375rem;
    font-weight: ${fontWeights.bold}
  }

  h1 {
    ${oFontSize(3)}
    ${oLineHeight(lineHeights.larger)}

    ${breakpoint.gt('md')`
      ${oFontSize(fontSizes.huge)}
      ${oLineHeight(lineHeights.huge)}
    `}
  }

  h2 {
    ${oFontSize(2.5)}
    ${oLineHeight(lineHeights.larger)}

    ${breakpoint.gt('md')`
      ${oFontSize(fontSizes.largest)}
      ${oLineHeight(lineHeights.largest)}
    `}
  }

  h3 {
    ${oFontSize(2)}
    ${oLineHeight(lineHeights.larger)}

    ${breakpoint.gt('md')`
      ${oFontSize(fontSizes.larger)}
    `}
  }

  h4 {
    ${oFontSize(1.5)}
    ${oLineHeight(lineHeights.large)}

    ${breakpoint.gt('md')`
      ${oFontSize(fontSizes.large)}
    `}
  }
`
