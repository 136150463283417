import { paletteText, breakpoints } from '@styles/01-settings'
import { bp } from '@styles/02-tools/tools.breakpoints'

export default `
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }


  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  li {
    margin: 0 0 8px;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: .5rem;
  }

  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${paletteText.border};
  }

  blockquote {
    margin: .8rem 0;
    padding: .5rem 1rem;
    border-left: .25rem solid ${paletteText.border};
    background-color: rgba(255,255,255,0.05);
    color: ${paletteText.text};
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    ${bp(breakpoints.sm, `padding-right: 5rem; padding-left: 1.25rem;`)}
  }
`
