import { rem } from '@styles/02-tools/tools.common'
import { ObjProps } from '@models/common.model'
import { bp } from '@styles/02-tools/tools.breakpoints'
import { breakpoints } from './settings.breakpoints'

export const fontFamilies: ObjProps<string> = {
  baseFamily: 'Jost',
}

export const fontColor: ObjProps<string> = {
  light: '#a09fb3',
  default: '#1B1A1A',
  dark: '#000',
}

export const fontWeights: ObjProps<number> = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}

export const fontStyles: ObjProps<string> = {
  italic: 'italic',
  normal: 'normal',
}

export const letterSpacings: ObjProps<number> = {
  largest: 20,
  larger: 10,
  large: 2,
  regular: 0,
  small: 1.56,
  smaller: -0.69,
}

export const lineHeights: ObjProps<number> = {
  smallest: rem(18),
  smaller: rem(22),
  small: rem(24),
  default: rem(28),
  large: rem(32),
  larger: rem(42.3),
  largest: rem(64),
  huge: rem(88.2),
}

export const fontSizes: ObjProps<number> = {
  huge: rem(84),
  largest: rem(64),
  larger: rem(32),
  large: rem(26),
  default: rem(22),
  small: rem(18),
  smaller: rem(16),
  smallest: rem(14),
}

export const oAlignLeftOnBigDisplay = () => `
  text-align: center;
  ${bp(breakpoints.md, `text-align:left;`)}
`

interface Font {
  colors: typeof fontColor
  heights: typeof lineHeights
  sizes: typeof fontSizes
  spacings: typeof letterSpacings
  weights: typeof fontWeights
}

export const font: Font = {
  colors: fontColor,
  heights: lineHeights,
  sizes: fontSizes,
  spacings: letterSpacings,
  weights: fontWeights,
}
