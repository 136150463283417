import React from 'react'

export type InitialState = {
  pageLoaded: boolean
}

const initialState: InitialState = {
  pageLoaded: false,
}

export const GlobalStateContext = React.createContext(initialState)
export const GlobalDispatchContext = React.createContext(initialState)

export const SCRIPT_LOADED_SUCCESS = 'SCRIPT_LOADED_SUCCESS'
export const PAGE_LOADED = 'PAGE_LOADED'

function reducer(state, action) {
  switch (action.type) {
    case 'PAGE_LOADED': {
      return {
        ...state,
        pageLoaded: (state.pageLoaded = true),
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const GlobalContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export { GlobalContextProvider }
