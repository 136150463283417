import * as prismic from 'ts-prismic'
import * as RTE from 'fp-ts/ReaderTaskEither'

import { aggregateQuery, AggregateQueryEnv } from './aggregateQuery'

export type QueryAllDocumentsEnv = AggregateQueryEnv

/**
 * Queries all documents from a Prismic repository using the environment's
 * configuration. The following fields from the environment's `pluginOptions`
 * are used, in addition to those used in `createClient`:
 *
 * - `pluginOptions.releaseID`: Prismic Release ID from which to fetch
 *   documents. If not provided, the master ref is used.
 *
 * - `pluginOptions.fetchLinks`: List of document link fields to fetch.
 *
 * - `pluginOptions.lang`: Language of documents to fetch. If not provided, all
 *   languages are fetched.
 *
 * @returns List of Prismic documents.
 */
export const queryAllDocuments: RTE.ReaderTaskEither<
  AggregateQueryEnv,
  Error,
  prismic.Document[]
> = aggregateQuery(null)
