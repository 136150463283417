import React, { ReactNode } from 'react'

const linksPreconnect = [
  'https://fonts.gstatic.com',
]
/**
 * @NOTE change in following https://web.dev/optimize-lcp/#establish-third-party-connections-early
 */
export function GenerateLinksPreconnect(): ReactNode {
  const preconnectElements = linksPreconnect.map((url, index) => {
    return <link key={`connect-${index}`} rel="preconnect" href={url} />
  })
  const prefetchElements = linksPreconnect.map((url, index) => {
    return <link key={`fetch-${index}`} rel="dns-prefetch" href={url} />
  })

  return [...preconnectElements, ...prefetchElements]
}
